<script lang="ts">
  import Star from "~icons/ant-design/star-filled";
  import ExternalLink from "~icons/tabler/ExternalLink";
  import ArrowRight from "~icons/tabler/arrow-narrow-right";
  import ArrowLeft from "~icons/tabler/arrow-narrow-left";
  import Quote from "@images/format_quote.svg";
  import ReviewsIO from "@images/reviews_logo_black.svg";
  import EmblaCarousel, {
    type EmblaCarouselType,
    type EmblaPluginType,
    type EmblaOptionsType
  } from "embla-carousel";
  import { onMount } from "svelte";
  import { client } from "@helpers/trpc/trpc-client";
  import { Image } from "@components/ux/image";

  const emblaConfig = {
    options: { loop: true, align: "start", dragFree: true } as EmblaOptionsType,
    plugins: [] as EmblaPluginType[]
  } as const;

  let emblaNode: HTMLDivElement;
  let emblaApi: EmblaCarouselType;

  //When emblaeNode is available, init embla
  $: emblaNode && init();

  const init = async () => {
    emblaApi = EmblaCarousel(emblaNode, emblaConfig.options);
  };

  const handleNextClick = () => {
    emblaApi?.scrollNext();
  };
  const handleBackClick = () => {
    emblaApi?.scrollPrev();
  };

  export let subtitle: string;

  //For schema
  export let sku: string;
  export let productName: string;

  //placeholder content
  let reviews_result: Awaited<ReturnType<typeof client.public.reviews.query>>;

  let schema = {};

  function getMultipleRandom(arr: any[], num: number) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  onMount(async () => {
    reviews_result = await client.public.reviews.query();

    schema = {
      "@context": "http://schema.org",
      "@type": "Product",
      aggregateRating: {
        "@type": "AggregateRating",
        bestRating: "5",
        ratingValue: reviews_result.stats.average_rating,
        reviewCount: reviews_result.stats.total_reviews,
        worstRating: 1
      },
      review: getMultipleRandom(reviews_result.reviews, 2).map((review) => {
        return {
          "@type": "Review",
          author: {
            "@type": "Person",
            name: review.reviewer.first_name
          },
          datePublished: review.date_created,
          description: "",
          reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1"
          },
          reviewBody: review.comments.replace(/&#039;/g, "'")
        };
      }),
      gtin: sku,
      mpn: sku,
      name: productName,
      image: "https://smurfers.net/images/logo.svg",
      sku: sku
    };
  });
</script>

<div class="container mt-10 grid place-items-center gap-4 text-center">
  <h2 class="flex-grow-0 text-4xl font-extrabold lg:text-5xl">
    Our <span class="text-gradient-colorful">Community</span>
  </h2>
  <p class="max-w-2xl text-xl font-semibold lg:text-2xl">
    {subtitle}
  </p>
  <div
    class="flex flex-wrap items-center justify-center gap-2 text-sm font-medium sm:text-xl">
    <h3 class=" inline-flex items-center gap-1 text-zinc-50">
      4.87 <Star class="h-3 w-3 md:h-5 md:w-5" />
    </h3>
    <span class="font-light">out of 5 based on 2.050 reviews on</span>
    <a
      href="https://www.reviews.io/company-reviews/store/smurfers"
      target="_blank"
      rel="noreferrer"
      class="hover:bg-gradient-colorful group relative ml-4 flex animate-background items-center gap-1 rounded-full bg-zinc-50 px-6 py-3 text-black hover:text-white">
      <div
        class="bg-gradient-colorful absolute -inset-0.5 -z-10 animate-background rounded-lg opacity-80 blur-lg" />

      <Image
        src={ReviewsIO}
        alt="Reviews.io Logo"
        class="h-5 group-hover:invert" />
      <ArrowRight />
    </a>
  </div>
</div>

<div class="mx-auto my-4 grid gap-2 overflow-hidden px-4 lg:mx-24">
  <div class="flex justify-end">
    <button
      on:click={handleBackClick}
      class="hover:bg-gradient-colorful group relative ml-4 flex animate-background items-center gap-1 rounded-full bg-zinc-50 p-4 text-black hover:text-white"
      ><ArrowLeft /></button>
    <button
      on:click={handleNextClick}
      class="hover:bg-gradient-colorful group relative ml-4 flex animate-background items-center gap-1 rounded-full bg-zinc-50 p-4 text-black hover:text-white"
      ><ArrowRight /></button>
  </div>
  {#if reviews_result && reviews_result.reviews.length > 0}
    <div
      bind:this={emblaNode}
      id="review-cards"
      class="mx-auto flex justify-center gap-4 overflow-hidden">
      <div class="embla__container flex flex-1 items-stretch gap-4 pl-4">
        {#each reviews_result.reviews as review}
          <div
            class="relative flex flex-[1_0_250px] flex-col justify-between gap-2 rounded-xl bg-neutral-900 p-5 text-white md:flex-[1_0_350px]">
            <Image src={Quote} alt="Quote" class="object-contan h-9 w-9" />

            <p class="text-md overflow-ellipsis py-4">
              {review.comments.replace(/&#039;/g, "'")}
            </p>

            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-between gap-2">
                <p
                  class="flex flex-col justify-around text-base font-light text-white">
                  {review.reviewer.first_name}
                  <small class="text-sm font-light text-theme-grey">
                    {review.date}
                  </small>
                </p>
                <ul class="flex items-center text-sm text-green-500">
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                </ul>
              </div>

              <a
                href={`https://www.reviews.io/company-review/store/smurfers/${review.store_review_id}`}
                target="_blank">
                View on Reviews.io
                <ExternalLink class="inline-block" />
              </a>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</div>

<!--Hide reviews when cart is open
<div
  class={`reviews-io-floating-widget ${$isCartClosed ? "" : "hidden sm:block"}`}
  data-widget-id="IYeqTrlb6Oug7GS4"
  data-store-name="smurfers"
  lang="en" />
{@html `<script defer src="https://widget.reviews.io/modern-widgets/floating.js"></script>`}-->

{@html `<script type="application/ld+json">
${JSON.stringify(schema)}
</script>`}
